var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alarm-report"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full bg-action-bar px-2 py-2 clearfix"},[_c('div',{staticClass:"float-left flex flex-wrap"},[_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.toggle_log == 0 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.toggleTable(0)}}},[_vm._v("Verksamhet")]),_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.toggle_log == 1 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.toggleTable(1)}}},[_vm._v("Larm")]),_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.toggle_log == 2 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.toggleTable(2)}}},[_vm._v("Logg")])])]),_c('div',{staticClass:"w-full mt-6 flex"},[_c('div',{staticClass:"w-full"},[_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_log == 0),expression:"toggle_log == 0"}],ref:"tableUnit",attrs:{"styleClass":"vgt-table striped bordered condensed","row-style-class":"text-sm","columns":_vm.columns_units,"rows":_vm.rows_units_filtered,"search-options":{
            enabled: false,
          },"pagination-options":{
            enabled: true,
            perPage: 50,
            perPageDropdown: [50, 100],
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'alarm_reminder')?_c('span',[(props.row.alarm_reminder == true)?_c('span',[_vm._v("Ja")]):_c('span',[_vm._v("Nej")])]):(props.column.field == 'notification_schedule')?_c('span',[(props.row.alarm_reminder == true)?_c('span',[(props.row.notification_schedule == 1)?_c('span',[_vm._v("Varje månad")]):(props.row.notification_schedule == 2)?_c('span',[_vm._v("Varannan månad")]):(props.row.notification_schedule == 3)?_c('span',[_vm._v("Varje kvartal")]):(props.row.notification_schedule == 4)?_c('span',[_vm._v("Första måndagen varje månad")]):(props.row.notification_schedule == 5)?_c('span',[_vm._v("Varje halvår")]):_vm._e()]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"flex"},[(_vm.customers)?_c('div',{staticClass:"mr-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_customer),expression:"selected_customer"}],staticClass:"bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_customer=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.selectChangeCustomer()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Alla Kund")]),_vm._l((_vm.customers),function(c){return _c('option',{key:c._id,domProps:{"value":c._id}},[_vm._v(_vm._s(c.name))])})],2)]):_vm._e(),_c('button',{staticClass:"btn-secondary-outline mx-1",on:{"click":function($event){_vm.selected_customer = '';
                  _vm.selectChangeCustomer();}}},[_vm._v(" Reset "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn-blue mx-1",on:{"click":function($event){return _vm.exportUnits()}}},[_vm._v(" Export "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"file-excel"}})],1)])])]),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_log == 1),expression:"toggle_log == 1"}],ref:"tableAlarm",attrs:{"styleClass":"vgt-table striped bordered condensed","row-style-class":"text-sm","columns":_vm.columns,"rows":_vm.rows_filtered,"search-options":{
            enabled: false,
          },"pagination-options":{
            enabled: true,
            perPage: 100,
            perPageDropdown: [100, 200, 300],
            dropdownAllowAll: false,
          }}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"flex"},[(_vm.units)?_c('div',{staticClass:"mr-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_unit),expression:"selected_unit"}],staticClass:"bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_unit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.selectChangeUnit()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Alla Verksamhet")]),_vm._l((_vm.units),function(u){return _c('option',{key:u._id,domProps:{"value":u.unit_id}},[_vm._v(_vm._s(u.name))])})],2)]):_vm._e(),_c('button',{staticClass:"btn-secondary-outline mx-1",on:{"click":function($event){_vm.selected_unit = '';
                  _vm.selectChangeUnit();}}},[_vm._v(" Reset "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn-blue mx-1",on:{"click":function($event){return _vm.exportAlarms()}}},[_vm._v(" Export "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"file-excel"}})],1)])])]),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_log == 2),expression:"toggle_log == 2"}],ref:"tableLog",attrs:{"styleClass":"vgt-table striped bordered condensed","row-style-class":"text-sm","columns":_vm.columns_log,"rows":_vm.rows_log_filtered,"search-options":{
            enabled: false,
          },"pagination-options":{
            enabled: true,
            perPage: 100,
            perPageDropdown: [100, 200, 400],
            dropdownAllowAll: false,
          }}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"flex"},[(_vm.customers)?_c('div',{staticClass:"mr-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_customer_log),expression:"selected_customer_log"}],staticClass:"bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_customer_log=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.selectChangeCustomerLog()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Alla Kund")]),_vm._l((_vm.customers),function(c){return _c('option',{key:c._id,domProps:{"value":c._id}},[_vm._v(_vm._s(c.name))])})],2)]):_vm._e(),_c('button',{staticClass:"btn-secondary-outline mx-1",on:{"click":function($event){_vm.selected_customer_log = '';
                  _vm.selectChangeCustomerLog();}}},[_vm._v(" Reset "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn-blue mx-1",on:{"click":function($event){return _vm.exportLogs()}}},[_vm._v(" Export "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"file-excel"}})],1)])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }