<template>
  <div class="alarm-report">
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_log == 0 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="toggleTable(0)">Verksamhet</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_log == 1 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="toggleTable(1)">Larm</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_log == 2 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="toggleTable(2)">Logg</div>
        </div>
      </div>
      <div class="w-full mt-6 flex">
        <div class="w-full">
          <vue-good-table
            styleClass="vgt-table striped bordered condensed"
            row-style-class="text-sm"
            ref="tableUnit"
            v-show="toggle_log == 0"
            :columns="columns_units"
            :rows="rows_units_filtered"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 50,
              perPageDropdown: [50, 100],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex">
                <div class="mr-2" v-if="customers">
                  <select class="bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1" v-model="selected_customer" @change="selectChangeCustomer()">
                    <option value="">Alla Kund</option>
                    <option v-for="c in customers" :key="c._id" :value="c._id">{{ c.name }}</option>
                  </select>
                </div>
                <button
                  class="btn-secondary-outline mx-1"
                  @click="
                    selected_customer = '';
                    selectChangeCustomer();
                  "
                >
                  Reset
                  <BaseIcon icon="redo" class="ml-1" />
                </button>
                <button class="btn-blue mx-1" @click="exportUnits()">
                  Export
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'alarm_reminder'">
                <span v-if="props.row.alarm_reminder == true">Ja</span>
                <span v-else>Nej</span>
              </span>
              <span v-else-if="props.column.field == 'notification_schedule'">
                <span v-if="props.row.alarm_reminder == true">
                  <span v-if="props.row.notification_schedule == 1">Varje månad</span>
                  <span v-else-if="props.row.notification_schedule == 2">Varannan månad</span>
                  <span v-else-if="props.row.notification_schedule == 3">Varje kvartal</span>
                  <span v-else-if="props.row.notification_schedule == 4">Första måndagen varje månad</span>
                  <span v-else-if="props.row.notification_schedule == 5">Varje halvår</span>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <vue-good-table
            styleClass="vgt-table striped bordered condensed"
            row-style-class="text-sm"
            ref="tableAlarm"
            v-show="toggle_log == 1"
            :columns="columns"
            :rows="rows_filtered"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 100,
              perPageDropdown: [100, 200, 300],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex">
                <div class="mr-2" v-if="units">
                  <select class="bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1" v-model="selected_unit" @change="selectChangeUnit()">
                    <option value="">Alla Verksamhet</option>
                    <option v-for="u in units" :key="u._id" :value="u.unit_id">{{ u.name }}</option>
                  </select>
                </div>
                <button
                  class="btn-secondary-outline mx-1"
                  @click="
                    selected_unit = '';
                    selectChangeUnit();
                  "
                >
                  Reset
                  <BaseIcon icon="redo" class="ml-1" />
                </button>
                <button class="btn-blue mx-1" @click="exportAlarms()">
                  Export
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
          </vue-good-table>
          <vue-good-table
            styleClass="vgt-table striped bordered condensed"
            row-style-class="text-sm"
            ref="tableLog"
            v-show="toggle_log == 2"
            :columns="columns_log"
            :rows="rows_log_filtered"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 100,
              perPageDropdown: [100, 200, 400],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex">
                <div class="mr-2" v-if="customers">
                  <select class="bg-white border border-secondary-300 rounded w-full text-sm text-secondary-700 focus:outline-none px-3 py-1" v-model="selected_customer_log" @change="selectChangeCustomerLog()">
                    <option value="">Alla Kund</option>
                    <option v-for="c in customers" :key="c._id" :value="c._id">{{ c.name }}</option>
                  </select>
                </div>
                <button
                  class="btn-secondary-outline mx-1"
                  @click="
                    selected_customer_log = '';
                    selectChangeCustomerLog();
                  "
                >
                  Reset
                  <BaseIcon icon="redo" class="ml-1" />
                </button>
                <button class="btn-blue mx-1" @click="exportLogs()">
                  Export
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminAlarmReminder",
  title() {
    return `Admin - Provlarmspåminnelse`;
  },
  data() {
    return {
      toggle_log: 0,
      alarms: null,
      customers: null,
      units: null,
      columns: [
        { label: "Verksamhet", field: "parent_id", formatFn: this.getUnitName },
        { label: "Namn på larm", field: "unit_name" },
        { label: "Senaste påminnelsedatum", field: "last_test_alarm", formatFn: this.formatDate },
      ],
      columns_log: [
        { label: "Verksamhet", field: "unit_name" },
        { label: "Rapport", field: "report" },
        { label: "Timestamp", field: "createdAt", formatFn: this.formatDate },
      ],
      columns_units: [
        { label: "Verksamhet", field: "name" },
        { label: "Email påminnelse", field: "alarm_reminder" },
        { label: "Påminnelseintervall", field: "notification_schedule" },
        { label: "Emailkontakt", field: "reminder_email" },
        { label: "Senast provlarm", field: "last_test_alarm", formatFn: this.formatDate },
      ],
      rows: [],
      rows_filtered: [],
      rows_log: [],
      rows_log_filtered: [],
      rows_units: [],
      rows_units_filtered: [],
      selected_customer: "",
      selected_unit: "",
      selected_customer_log: "",
    };
  },

  methods: {
    async getAlarms() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms`);
        this.alarms = response.data.data;
        this.alarms = _.filter(this.alarms, function(o) {
          return ["SRT330", "SRT334", "TWIG Beacon", "Anchor Beacon"].indexOf(o.type) == -1;
        });
        this.rows = _.sortBy(this.alarms, "parent_id");
        this.rows_filtered = this.rows;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getUnits() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/units`);
        this.units = response.data.data;
        this.rows_units = _.sortBy(this.units, "parent_id");
        this.rows_units_filtered = this.rows_units;

        this.getAlarms();
      } catch (error) {
        this.handleError(error);
      }
    },

    async getTestAlarmLogs() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/admin/test-alarm-log/`);
        this.rows_log = response.data.test_alarm_logs;
        this.rows_log_filtered = this.rows_log;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getCustomers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/customer`);
        this.customers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    selectChangeCustomer() {
      if (this.selected_customer != "") {
        this.rows_units_filtered = _.filter(this.rows_units, { parent_id: this.selected_customer });
      } else {
        this.rows_units_filtered = this.rows_units;
      }
    },

    selectChangeUnit() {
      if (this.selected_unit != "") {
        this.rows_filtered = _.filter(this.rows, { parent_id: this.selected_unit });
      } else {
        this.rows_filtered = this.rows;
      }
    },

    selectChangeCustomerLog() {
      if (this.selected_customer_log != "") {
        this.rows_log_filtered = _.filter(this.rows_log, { customer_id: this.selected_customer_log });
      } else {
        this.rows_log_filtered = this.rows_log;
      }
    },

    exportUnits() {
      var vm = this;

      const newColumns = this.rows_units_filtered.map(function(item) {
        let notification_schedule = "";

        if (item.alarm_reminder) {
          switch (item.notification_schedule) {
            case 1:
              notification_schedule = "Varje månad";
              break;
            case 2:
              notification_schedule = "Varannan månad";
              break;
            case 3:
              notification_schedule = "Varje kvartal";
              break;
            case 4:
              notification_schedule = "Första måndagen varje månad";
              break;
            case 5:
              notification_schedule = "Varje halvår";
              break;
            default:
              notification_schedule = "";
          }
        }
        return {
          Verksamhet: item.name,
          "Email påminnelse": item.alarm_reminder == true ? "Ja" : "Nej",
          Påminnelseintervall: notification_schedule,
          Emailkontakt: item.reminder_email,
          "Senast provlarm": vm.formatDate(item.last_test_alarm),
        };
      });

      this.excelExport(newColumns, "verksamhet", "provlarm_verksamhet");
    },

    exportAlarms() {
      var vm = this;

      const newColumns = this.rows_filtered.map((item) => ({
        Verksamhet: vm.getUnitName(item.parent_id),
        "Namn på larm": item.unit_name,
        "Senaste påminnelsedatum": vm.formatDate(item.last_test_alarm),
      }));

      this.excelExport(newColumns, "larms", "provlarm_larms");
    },

    exportLogs() {
      var vm = this;
      const newColumns = this.rows_log_filtered.map((item) => ({
        Verksamhet: item.unit_name,
        Rapport: item.report,
        Timestamp: vm.formatDate(item.createdAt),
      }));

      this.excelExport(newColumns, "larms", "provlarm_larms");
    },

    getUnitName(unit_id) {
      let unit = _.find(this.units, { unit_id: unit_id });
      if (unit) return unit.name;
      else return "";
    },

    formatDate(date) {
      if (date == null) return "-";
      else return this.moment(date).format("YYYY-MM-DD");
    },

    toggleTable(active) {
      this.toggle_log = active;
      this.$refs.tableUnit.reset();
      this.$refs.tableAlarm.reset();
      this.$refs.tableLog.reset();
    },
  },

  created() {
    this.getUnits();
    this.getTestAlarmLogs();
    this.getCustomers();

    this.setPageTitle(`Admin - Provlarmspåminnelse`, "admin_alarm_reminder");
  },
};
</script>
